
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq } from "@/lib/amdt";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ACard from "@/components/elements/a-card.vue";
import IAlog from "@/lib/interfaces/alog";

export default Vue.extend({
  name: "LogMaintenance",

  components: {
    ACard,
    vAlertMessage,
  },

  props: {
    log: {
      type: Object as PropType<IAlog>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      dataLog: {
        id: "",
        id_tipo_operacao: "",
        id_regiao: "",
        id_modulo: "",
        id_usuario: "",
        nome_database: "",
        corpo: "",
        data_ocorrencia: "",
        desativado: "0",
      } as IAlog,
    };
  },

  watch: {
    log: {
      deep: true,
      handler() {
        this.getLog().then();
      },
    },
  },

  computed: {
    corpo(): string {
      try {
        return JSON.stringify(JSON.parse(this.dataLog.corpo), null, "\t");
      } catch (error) {
        return "";
      }
    },
  },

  methods: {
    async getLog() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("alog.read", {
          id: this.log.id,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        const logs = rsp.getTable("alog")?.getRowsObject() ?? [];
        if (logs.length > 0) this.dataLog = logs[0];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
