
import Vue from "vue";
import IAlog from "@/lib/interfaces/alog";
import LogList from "@/components/subviews/log/log-list.vue";
import LogMaintenance from "@/components/subviews/log/log-maintenance.vue";

export default Vue.extend({
  name: "Log",

  components: { LogList, LogMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      currentTab: 0,

      log: {} as IAlog,
    };
  },
});
