
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq } from "@/lib/amdt";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import IAlog from "@/lib/interfaces/alog";
import IModule from "@/lib/interfaces/module";
import { DataOptions } from "vuetify";
import ACard from "@/components/elements/a-card.vue";
import ADate from "@/lib/a-date";
import ADataTable from "@/components/elements/a-data-table.vue";
import IUser from "@/lib/interfaces/user";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import IPerson from "@/lib/interfaces/person";
import IPermission from "@/lib/interfaces/permission";
import IOperationType from "@/lib/interfaces/operation-type";
import ABtn from "@/components/elements/a-btn.vue";

export default Vue.extend({
  name: "LogList",

  components: {
    vAlertMessage,
    ATextField,
    ACard,
    ADataTable,
    AAutocomplete,
    ABtn,
  },

  props: {
    log: {
      type: Object as PropType<IAlog>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",

      idRegiao: "current",
      idModule: "all",
      idUsuario: "all",
      dataFinal: new ADate().dateString,
      dataInicial: new ADate().dateString,

      options: {} as DataOptions,

      people: new Array<IPerson>(),
      logs: new Array<IAlog>(),
      users: new Array<IUser>(),
      modules: new Array<IModule>(),
      operationTypes: new Array<IOperationType>(),

      headers: [
        { text: "Operação", value: "cTipoOperacao" },
        { text: "Região", value: "cRegiao" },
        { text: "Módulo", value: "cModulo" },
        { text: "Usuário", value: "cUsuario", sortable: false },
        { text: "Banco de dados", value: "nome_database" },
        { text: "Ocorrência", value: "cDataOcorrencia" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.getLogs().then();
      },
    },
  },

  computed: {
    cLogs(): Array<Record<string, unknown>> {
      return this.logs.map((log) => ({
        ...log,

        cTipoOperacao:
          this.operationTypes.find((type) => type.id === log.id_tipo_operacao)
            ?.desc_operacao ?? log.id_tipo_operacao,

        cRegiao:
          this.people.find((person) => person.id === log.id_regiao)?.nome_raz ??
          log.id_regiao
            ? log.id_regiao
            : "SEM REGIÃO",

        cModulo:
          this.modules.find((mod) => mod.id === log.id_modulo)?.nome ??
          log.id_modulo
            ? log.id_modulo
            : "SEM MÓDULO",

        cUsuario:
          this.users.find((user) => user.id === log.id_usuario)?.usuario ??
          log.id_usuario
            ? log.id_usuario
            : "SEM USUÁRIO",

        cDataOcorrencia: ADate.parse(log.data_ocorrencia).localeDateTimeString,
      }));
    },

    cPermissions(): Array<IPermission> {
      return this.$store.state.permissions;
    },

    cVerOutrasRegioes(): boolean {
      const leitura: "1" | "0" =
        this.cPermissions.find(
          (perm) => perm.identificador === "log.ver_outras_regioes"
        )?.leitura ?? "0";

      return leitura === "1";
    },

    cPeople(): IPerson[] {
      const options = new Array<IPerson>({
        id: "current",
        referente_id: "",
        empresa_id: "",
        nome_raz: "Minha região",
        apelido_fant: "",
        cpf_cnpj: "",
        doc_ie: "",
        tipo: "",
        inf_adicional: "",
        data_criacao: "",
        desativado: "0",
      });

      if (this.cVerOutrasRegioes)
        options.push(
          {
            id: "all",
            referente_id: "",
            empresa_id: "",
            nome_raz: "Todos",
            apelido_fant: "",
            cpf_cnpj: "",
            doc_ie: "",
            tipo: "",
            inf_adicional: "",
            data_criacao: "",
            desativado: "0",
          },
          {
            id: "null",
            referente_id: "",
            empresa_id: "",
            nome_raz: "Sem região",
            apelido_fant: "",
            cpf_cnpj: "",
            doc_ie: "",
            tipo: "",
            inf_adicional: "",
            data_criacao: "",
            desativado: "0",
          }
        );

      options.push(...this.people);

      return options;
    },

    cUsers(): IUser[] {
      return [
        {
          id: "current",
          usuario: "Meu usuário",
          senha: "",
          email: "",
          dias_expiracao_senha: "",
          data_prox_expiracao_senha: "",
          dispositivo: "0",
          data_criacao: "",
          desativado: "0",
        },
        {
          id: "all",
          usuario: "Todos",
          senha: "",
          email: "",
          dias_expiracao_senha: "",
          data_prox_expiracao_senha: "",
          dispositivo: "0",
          data_criacao: "",
          desativado: "0",
        },
        {
          id: "null",
          usuario: "Sem usuário",
          senha: "",
          email: "",
          dias_expiracao_senha: "",
          data_prox_expiracao_senha: "",
          dispositivo: "0",
          data_criacao: "",
          desativado: "0",
        },
        ...this.users,
      ];
    },

    cModules(): IModule[] {
      return [
        {
          id: "all",
          nome: "Todos",
          proc_run: "",
          ordem_menu: "",
          icone: "",
          id_modulo_pai: "",
          id_modulo_filho: "",
          desativado: "0",
        },
        {
          id: "null",
          nome: "Sem módulo",
          proc_run: "",
          ordem_menu: "",
          icone: "",
          id_modulo_pai: "",
          id_modulo_filho: "",
          desativado: "0",
        },
        ...this.modules,
      ];
    },
  },

  mounted() {
    this.getModules().then();
    this.getUsers().then();
    this.getPeople().then();
    this.getOperationTypes().then();
  },

  methods: {
    async openLogs(id: string) {
      const log = this.logs.find((log) => log.id === id);
      this.$emit("update:log", { ...log });
    },

    async getLogs() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("alog.read", {
          search: this.search,
          idRegiao: this.idRegiao,
          idModule: this.idModule,
          idUsuario: this.idUsuario,
          dataInicial: this.dataInicial,
          dataFinal: this.dataFinal,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.logs = rsp.getTable("alog")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getOperationTypes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("tipo_operacao.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.operationTypes =
          rsp.getTable("tipo_operacao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getModules() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("module.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.modules = rsp.getTable("modulo")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getUsers() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("user.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.users = rsp.getTable("usuario")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPeople() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.people = rsp.getTable("pessoas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
